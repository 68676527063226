
import React from "react"
import Layout from '../components/layout/layout.higherComponent'
import Head from '../components/head'
import ServicesPage from '../components/servicesPage/servicesPage'
const Services = props => {
  return (
    <Layout>
      <Head title="Services" />
      <ServicesPage />
    </Layout>
  )
}

export default Services
