import React from "react"
import { withTranslation } from "react-i18next"
// import {DATASERVICES} from './../../../../data/dataServices'
import "./servicesPageTitle.scss"
const ServicesPageTitle = props => {
  const { t } = props
  return (
    <section className="headerServices header--services">
      <div className="container">
        <h1 className="title title--uppercase title--margin-top white-text">
          <span className="title__preheader title__preheader--tag">
            {t("src.pages.servicesPage.services")}
          </span>
          {t("src.pages.servicesPage.title")}
        </h1>
        <p className="header__lead">
          {t("src.pages.servicesPage.descriptions")}
        </p>
      </div>
    </section>
  )
}

export default withTranslation()(ServicesPageTitle)
