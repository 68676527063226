import React from "react"
import { withTranslation } from "react-i18next"
// import {
//   DataServicesAbout1,
//   DataServicesAbout2,
//   DataServicesAboutTitle
// } from "../../../../data/dataServices"
import ViewMoreBtn from "./viewMorebtn"
import "./customServices.scss"
import { Link } from "gatsby"

// import img1 from "../../../../assets/img/media/services/servicesAbout/dedicated-team.svg"
// import img2 from "../../../../assets/img/media/services/servicesAbout/mobile-app-dev.svg"
// import img3 from "../../../../assets/img/media/services/servicesAbout/enterprise.svg"
import image1 from "../../../../assets/img/media/services/servicesAbout/mobile-app-dev.svg"

import image2 from "../../../../assets/img/media/services/servicesAbout/it-consulting.svg"
import image3 from "../../../../assets/img/media/services/servicesAbout/quality-assurance.svg"
import image4 from "../../../../assets/img/media/services/servicesAbout/devops.svg"

// const X = {
//   img1,
//   img2,
//   img3
// }

const Y = {
  image1,
  image2,
  image3,
  image4
}

const CustomServices = props => {
  const { t } = props
  return (
    <section className="servicesAboutCover">
      <div className="container">
        <h2 className="services__title">
          {t("src.pages.servicesPage.customSoftware.title")}
        </h2>
        <p className="services__description">
          {t("src.pages.servicesPage.customSoftware.content")}
        </p>
        <div className="services__items">
          <div className="items items--col-sm-2 items--col-4">
            {t("src.pages.servicesPage.customSoftware.data").map((div, idx) => (
              <div key={idx} className="item">
                <div className="item__description">
                  <img
                    style={{ marginTop: `${div.styleImg}px` }}
                    src={Y[`image${idx + 1}`]}
                    alt="Software Testing and QA"
                    className="item__description-logo"
                  />
                  <h3
                    style={{ marginTop: `${div.styleText}px` }}
                    className="item__description-title"
                  >
                    {div.title}
                  </h3>
                </div>
                <div className="item__link">
                  <Link to={div.link}>
                    <h3 className="item__link-title">{div.title}</h3>
                    <p className="item__link-text">{div.content}</p>
                    <ViewMoreBtn />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(CustomServices)
