import React, { Component } from "react"
import ServicesPageTitle from "./servicesHomePage/servicesPageTitle/servicesPageTitle"
import CustomServices from "./servicesHomePage/servicesCustom/customServices"
import ClientsServices from "./servicesHomePage/servicesClients/clientsServices"
import TalkToOurTeam from "../homepageInnos/about/TalkToOurTeam"

class ServicesPage extends Component {
  render() {
    return (
      <>
        <ServicesPageTitle />
        <CustomServices />
        <ClientsServices />
        <TalkToOurTeam />
      </>
    )
  }
}

export default ServicesPage
